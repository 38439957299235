import type { ProductHandles$result } from '$houdini';
import type { CompleteVariant, Money } from '$lib/types';
import type { ProductDetails } from '$lib/types/product';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';

export type MetaField =
	| {
			key: string;
			value?: string;
	  }
	| null
	| undefined;

export type CrossSellItem = Partial<CompleteVariant['crossSellReferences']> | null | undefined;

export const timeoutPromise = <E>(timeout: number) =>
	new Promise<E>((_, reject) => setTimeout(() => reject(new Error('Failed to fetch')), timeout));

export const withTimeout = <E>(promise: Promise<E>, timeout: number) => {
	return Promise.race([promise, timeoutPromise<E>(timeout)]);
};

export async function sha256(text: string) {
	const textAsBuffer = new TextEncoder().encode(text);
	const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const digest = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
	return digest;
}

export const isNetworkError = (error: unknown) => {
	const knownMessages = [
		'Failed to fetch',
		'NetworkError when attempting to fetch resource.',
		'Network request failed',
		'Load failed',
		'network error',
		'Failed to fetch dynamically imported module',
		'Error in input stream'
	];

	return error instanceof Error && knownMessages.some((message) => error.message.includes(message));
};

export const withRetry = async <E>(func: () => Promise<E>, retries = 3): Promise<E> => {
	try {
		return await func();
	} catch (e) {
		if (retries <= 0 && !isNetworkError(e)) {
			throw e;
		}
		await sleep(2000 * Math.max(3 - retries + 1, 1));
		return withRetry(func, retries - 1);
	}
};

/**
 * Whether the app runs in stg mode.
 */
export function isStg(): boolean {
	return PUBLIC_ENVIRONMENT.includes('staging') || PUBLIC_ENVIRONMENT.includes('stg');
}

/**
 * Whether the app runs in prod mode.
 */
export function isProd(): boolean {
	return !isStg();
}

type IsEqualFn<T> = (a: T, b: T) => boolean;
/**
 * Removes duplicate elements from an array.
 *
 * If no comparator is provided, the default comparator is used which checks
 * for reference equality.
 * @param items The array to remove duplicates from.
 * @param comparator A comparator function.
 */
export function deduplicate<E>(items: E[], comparator: IsEqualFn<E> = (i1, i2) => i1 === i2): E[] {
	return items.reduce((acc, curr) => {
		const item = acc.findIndex((e) => comparator(e, curr));
		if (item === -1) {
			acc.push(curr);
		}
		return acc;
	}, [] as E[]);
}

export async function sleep(ms = 0) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const sumMoney: (...money: (Money | undefined | null)[]) => Money = (
	...money: (Money | undefined | null)[]
) => {
	if (money.length === 0) {
		throw new Error('No money to sum');
	}
	let sum;
	for (let i = 0; i < money.length; i++) {
		const element = money[i];

		if (!element) {
			continue;
		}

		if (!sum) {
			sum = element;
			continue;
		}

		if (element.currencyCode !== sum.currencyCode) {
			throw new Error('Cannot sum different currencies');
		}
		sum = {
			amount: Number(sum.amount) + Number(element.amount),
			currencyCode: sum.currencyCode
		};
	}
	return (
		sum ?? {
			amount: 0,
			currencyCode: 'EUR'
		}
	);
};

export const image_urlsAsArray = (image_urls?: string | null) =>
	image_urls?.startsWith('https://')
		? [image_urls]
		: (JSON.parse(image_urls ?? '[]') as Array<string>);

export function firstWithElements(...items: CrossSellItem[]): CrossSellItem {
	for (const item of items) {
		try {
			const possible = JSON.parse(item?.value ?? '[]');
			if (possible.length !== 0) {
				return item as CrossSellItem;
			}
			// eslint-disable-next-line no-empty
		} catch (e) {}
	}
	return null;
}

export function getBrancheFromCollections(
	collections?: NonNullable<
		ProductHandles$result['products']['edges'][number]
	>['node']['collections']
): ProductDetails['branche'] {
	return collections?.edges.some((e) => e.node.title === 'Pferdesport')
		? 'equine-sport'
		: collections?.edges.some((e) => e.node.title === 'Bootsport')
			? 'boat-sport'
			: collections?.edges.some((e) => e.node.title === 'Hundesport')
				? 'dog-sport'
				: '';
}

/**
 * Returns the shopify id of the base64 encoded id
 * @param base The id of the variant.
 */
export function getVariantIdFromShopifyUri(base: string): string {
	return base.replace('gid://shopify/ProductVariant/', '');
}

/**
 * Returns the shopify id of the base64 encoded id
 * @param base The id of the variant.
 */
export function getProductIdFromShopifyUri(base: string): string {
	return base.replace('gid://shopify/Product/', '');
}
